export const useAppStoreLink = () => {
  const config = useConfigStore();
  const device = useDevice();

  let storeLink = config.playstoreUrl!;

  if (device.isIos) {
    storeLink = config.appstoreUrl!;
  } else if (device.isHarmony) {
    storeLink = config.appgalleryUrl!;
  }

  return storeLink;
};
